<template>
    <div class="company-user-index">
        <div class="index-header">
            <div class="header-photo"></div>
            <div class="company-info">
                <div class="company-header">
                    <div class="company-cover">
                        <img :src="companyInfo.logo" alt="">
                    </div>
                    <span class="company-name">{{companyInfo.name}}</span>
                </div>
                <div class="publish-num">
                    <span style="margin-right: 20px;">发布任务数：{{companyInfo.taskCount}}</span>
                    <span>发布商品数：{{companyInfo.taskCount}}</span>
                </div>
            </div>
        </div>
        <el-tabs v-model="companyTab" @tab-click="changeCompanyTab" class="annex-tab">
            <el-tab-pane label="他发布的任务" name="task">
                <div class="company-task">
                    <div class="task-list">
                        <div class="list-item" v-for="item in companyAllTaskList" @click="viewCompanyTaskDetail(item)">
                            <div class="container">
                                <div class="item-cover">
                                    <img :src="item.task_cover" alt="">
                                    <!--<img v-if="item.enroll === '已报名'" src="../../assets/img/image/no-apply.png" class="sign" alt="">-->
                                    <img v-if="item.enroll === '已报名'" src="../../assets/img/image/applyed.png" class="sign" alt="">
                                    <img v-if="item.enroll === '需报名'" src="../../assets/img/image/need-apply.png" class="sign" alt="">
                                </div>
                                <div class="item-content">
                                    <span class="item-title">{{item.task_title}}</span>
                                    <div class="item-info">
                                        <div class="item-author">
                                            <div class="item-avatar">
                                                <img :src="companyInfo.logo" alt="">
                                                <span>{{companyInfo.name}}</span>
                                            </div>
                                            <span>{{item.create_time}}</span>
                                        </div>
                                        <div class="item-evaluate">
                                            <span class="evaluate-num">参与人数:{{item.join_num}}人</span>
                                            <div class="shangjin">
                                                <div class="evaluate-status">
                                                    悬赏金额:
                                                    <span>{{item.task_money}}</span>
                                                </div>
                                                <img src="../../assets/img/image/company-task-1.png" class="sign" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-status">
                                    <span class="view-task" v-if="item.state === 2">查看任务 ></span>
                                    <span class="finish-task" v-if="item.state === 1">已完成</span>
                                    <span class="time-out-task" v-if="item.state === 0">已截止</span>
                                </div>
                            </div>
                        </div>
                        <div class="itemHidden"></div>
                        <div class="itemHidden"></div>
                        <div class="itemHidden"></div>
                        <div class="itemHidden"></div>
                        <div class="itemHidden"></div>
                    </div>
                    <el-pagination
                            class="pages-center"
                            :current-page="companyIndexPages.currentPageNum"
                            :page-size="companyIndexPages.eachPageNum"
                            layout="prev, pager, next"
                            :total="companyIndexPages.total"
                            @current-change="companyIndexCurrentChange">
                    </el-pagination>
                </div>
            </el-tab-pane>
            <el-tab-pane label="他发布的商品" name="goods">
                <div class="goods-list">
                    <div class="list-item" v-for="item in goodsList">
                        <div class="img">
                            <img :src="item.goods_cover" alt="">
                            <div class="goods-handle">
                                <a :href="item.coupon_url" class="handle-item" target="_blank">一键领券</a>
                                <span class="handle-item-span"></span>
                                <a :href="item.goods_url" class="handle-item" target="_blank">立即下单</a>
                            </div>
                        </div>
                        <div class="goods-info">
                            <div class="goods-name">{{item.goods_name}}</div>
                            <div class="handle">
                                <div class="handle-item">
                                    <span class="num">{{item.goods_price}}</span>
                                    <span class="text">券后</span>
                                </div>
                                <div class="handle-item">
                                    <span class="num">{{item.goods_commission}}%</span>
                                    <span class="text">营销</span>
                                </div>
                                <div class="handle-item">
                                    <span class="num">{{item.goods_commission_money}}</span>
                                    <span class="text">佣金</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <el-pagination
                        class="pages-center"
                        :current-page="companyGoodsPages.currentPageNum"
                        :page-size="companyGoodsPages.eachPageNum"
                        layout="prev, pager, next"
                        :total="companyGoodsPages.total"
                        @current-change="companyGoodsCurrentChange">
                </el-pagination>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    export default {
        name: "CompanyUserIndex",
        data() {
            return {
                //企业任务
                companyAllTaskList: [],
                //企业信息
                companyInfo: {
                    user_id: '',
                    logo: '',
                    name: '',
                    taskCount: '',
                    goodsCount: ''
                },
                //分页
                companyIndexPages: {
                    currentPageNum: 1,
                    eachPageNum: 12,
                    total: 0
                },
                companyGoodsPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                //
                companyTab: 'task',
                goodsList: []
            }
        },
        mounted() {
            this.getCompanyAllTask();
        },
        methods: {
            //任务列表
            getCompanyAllTask() {
                let param = {
                    user_id: this.$route.query.c_id
                }
                this.$httpStudent.axiosGetBy(this.$api.companyTaskList, param, (res) => {
                    if (res.code === 200) {
                        this.companyAllTaskList = res.list;
                        if (res.user_info) {
                            this.companyInfo.user_id = res.user_info.id;
                            this.getCompanyInfo();
                        }
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.msg
                        });
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //获取企业信息
            //获取企业信息
            getCompanyInfo() {
                let param = {
                    user_id: this.companyInfo.user_id,
                    statistics: 'show'
                }
                this.$httpStudent.axiosGetBy(this.$api.enterprise_getEnterpriseDetail, param, (res) => {
                    if (res.code === 200) {
                        if (res.data) {
                            this.companyInfo.name = res.data.company_profile.name;
                            this.companyInfo.logo = res.data.company_profile.logo.scr;
                            this.companyInfo.taskCount = res.data.enterpriseNum.taskCount;
                            this.companyInfo.goodsCount = res.data.enterpriseNum.goodsCount;
                        }
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //分页
            companyIndexCurrentChange(val) {
                this.companyIndexPages.currentPageNum = val;
                this.getCompanyAllTask();
            },
            companyGoodsCurrentChange(val) {
                this.companyGoodsPages.currentPageNum = val;
                this.getGoodsList();
            },
            //查看任务详情
            viewCompanyTaskDetail(item) {
                this.$router.push({
                    path: '/student/task/detail',
                    query: {
                        id: item.task_id
                    }
                })
            },
            //
            changeCompanyTab() {

                if (this.companyTab === 'goods') {
                    this.getGoodsList();
                }
            },
            //商品列表
            getGoodsList() {
                let param = {
                    page: this.companyGoodsPages.currentPageNum,
                    limit: this.companyGoodsPages.eachPageNum,
                    user_id: this.companyInfo.user_id
                }
                this.$httpStudent.axiosGetBy(this.$api.goodsList, param, (res) => {
                    if (res.code === 200) {
                        this.goodsList = res.list;
                        this.companyGoodsPages.total = res.total;
                    }
                }, (err) => {
                    console.log(err);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .company-user-index {
        width: 100%;
        height: 100%;
        min-height: calc(100vh - 110px);
        .index-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 350px;
            position: relative;
            .header-photo {
                position: absolute;
                background: url("../../assets/img/image/company-index-bg.png") no-repeat 0 0;
                background-size:  100% 100%;
                width: 100%;
                height: 100%;
            }
            .company-info {
                position: absolute;
                z-index: 9;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .company-header {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .company-cover {
                        width: 174px;
                        height: 174px;
                        margin-bottom: 34px;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                    .company-name {
                        color: #fff;
                        font-size: 24px;
                        font-weight: 500;
                    }
                }
            }
            .publish-num {
                color: #fff;
                font-size: 16px;
                font-weight: 500;
                margin-top: 20px;
                text-align: center;
            }
        }
        .company-publish-num {
            display: block;
            margin: 20px 0;
            font-size: 16px;
            font-weight: 500;
        }
        .task-list {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            /*height: calc(100% - 52px);*/
            min-height: 370px;
            /*margin-top: 20px;*/
            padding-left: 24px;
            justify-content: flex-start;
            .list-item {
                flex: 1 1 0%;
                display: inline-flex;
                min-width: 220px;
                height: 290px;
                margin-right: 24px;
                margin-bottom: 27px;
                .container {
                    width: 100%;
                    border: 1px solid #E3E4EC;
                    transition: all .25s ease-out;
                    border-radius: 8px;
                    cursor: pointer;
                    .item-cover {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 138px;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                            /*border-radius:8px 8px 0 0;*/
                        }
                        img.sign {
                            position: absolute;
                            border-radius: 0;
                            top: 25px;
                            left: 0;
                            opacity: .8;
                            width: 80px;
                        }
                    }
                    .item-content {
                        padding: 10px;
                        .item-title {
                            display: block;
                            overflow: hidden;
                            text-overflow:ellipsis;
                            white-space: nowrap;
                        }
                        .item-info {
                            .item-author {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                .item-avatar {
                                    display: flex;
                                    align-items: center;
                                    margin: 10px 0;
                                    img {
                                        width: 24px;
                                        height: 24px;
                                        margin-right: 10px;
                                        border-radius: 50%;
                                    }
                                    span {
                                        display: block;
                                        width: 50px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }
                                }
                            }
                            .item-evaluate {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                font-size: 12px;
                                i {
                                    color: #F19149;
                                    margin-right: 2px;
                                }
                                .shangjin {
                                    width: 110px;
                                    height: 30px;
                                    display: flex;
                                    align-items: center;
                                    position: relative;
                                    img {
                                        max-width: 100%;
                                        max-height: 100%;
                                    }
                                    .evaluate-status {
                                        position: absolute;
                                        left: 20px;
                                        color: #fff;
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                    .item-status {
                        height: 38px;
                        line-height: 38px;
                        text-align: center;
                        margin: 0 10px;
                        border-top: 1px solid #E5E5E5;
                        .view-task {
                            color: #2338E6;
                        }
                        .finish-task {
                            color: #20C997;
                        }
                        .time-out-task {
                            color: #666666;
                        }
                    }
                }
            }
            .itemHidden {
                flex: 1 1 0%;
                min-width: 220px;
                visibility: hidden;
                margin-right: 24px;
            }
        }
        .pages-center {
            background-color: #fff;
            margin-top: 0;
        }
        .company-task {
            height: calc(100% - 434px);
            padding-top: 20px;
            background-color: #fff;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
                padding: 0 40px;
            }
        }
    }

    ::v-deep .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #EEEEEE;
    }
    ::v-deep .el-tabs__item {
        height: 45px;
        line-height: 45px;
        font-size: 14px;
        font-weight: 400;
        border: none;
        color: #5F5C74;
    }
    ::v-deep .el-tabs__item.is-active {
        color: #16113F;
    }

    ::v-deep .el-tabs__active-bar {
        background: #9ACC5B;
    }

    ::v-deep .el-tabs__active-bar:before {
        position: absolute;
        top: -13px;
        left: 40%;
        content: '';
        width: 0;
        height: 0;
        border-style: dashed dashed solid dashed;
        border-width: 7px;
        border-color: transparent transparent #9ACC5B transparent;
    }
    .annex-tab {
        ::v-deep .el-tabs__item {
            font-size: 16px;
            font-weight: 500;
        }
        height: 100%;
        margin-top: 5px;
        ::v-deep .el-tabs__content {
            height: calc(100% - 60px);
            ::v-deep .el-tab-pane {
                display: flex;
                flex-direction: column;
                height: 100%;
            }
        }
        ::v-deep .el-table__row {
            td:nth-child(1) .cell {
                display: flex;
                justify-content: start;
                align-items: center;
                cursor: pointer;
                .video-cover {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50px;
                    min-width: 50px;
                    height: 40px;
                    margin: 0 10px 0 0;
                    padding: 0;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }
        .goods-list {
            height: 100%;
            min-height: calc(100vh - 514px);
            background-color: #fff;
            ::v-deep .el-table {
                min-height: calc(100vh - 570px);
            }
        }
        .goods-list {
            flex: 1;
            height: 1%;
            padding: 17px 50px;
            margin-right: -20px;
            display: flex;
            flex-wrap: wrap;
            .list-item {
                /*width: calc(16% - 14px);*/
                width: 215px;
                height: 323px;
                margin: 20px 20px 8px 0;
                border-radius: 4px;
                border: 1px solid #EEEEEE;
                &:hover {
                    cursor: pointer;
                    box-shadow: 0px 2px 8px 0px rgba(96, 86, 241, 0.27);
                    border-radius: 0px 4px 4px 0px;
                    .goods-handle {
                        opacity: 0.8;
                        animation: fadeInBottom .5s;
                    }
                }
                .img {
                    position: relative;
                    /*width: 100%;*/
                    width: 215px;
                    height: 215px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .goods-handle {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    background: #FF5200;
                    opacity: 0;
                    .handle-item {
                        width: 49.7%;
                        height: 28px;
                        line-height: 28px;
                        color: #fff;
                        text-align: center;
                        cursor: pointer;
                    }
                    .handle-item-span {
                        display: inline-block;
                        width: 0.6%;
                        background-color: #fff;
                        height: 16px;
                    }
                }
                .goods-info {
                    padding: 10px;
                    .goods-name {
                        height: 34px;
                        font-size: 12px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                    }
                    .handle {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 10px;
                        .handle-item {
                            display: flex;
                            flex-direction: column;
                            width: 45%;
                            text-align: center;
                            .num {
                                font-size: 16px;
                                color: #FF5400;
                                font-weight: 500;
                            }
                            .text {
                                font-size: 12px;
                                color: #999999;
                                margin-top: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (width: 1680px) {
        .goods-list {
            padding: 17px 30px !important;
        }
        .list-item {
            margin: 20px 12px 8px 0!important;
        }
    }
    @media screen and (width: 1920px) {
        .list-item {
            margin: 20px 20px 0 21px !important;
        }
    }
    @keyframes fadeInBottom {
        0% {
            opacity: 0;
            transform: translateY(5px);
            -webkit-transform: translateY(5px);
        }
        100% {
            opacity: 0.8;
            transform: translateY(0);
            -webkit-transform: translateY(0);
        }
    }
</style>